import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
            <AppsNavigation/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

            <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} key="dashboard">
              <Link to="/dashboard">
              <IntlMessages id="sidebar.main"/></Link>
            </Menu.Item>
            <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="master"
                    title={<IntlMessages id="sidebar.master"/>}>
              <Menu.Item key="admin">
                <Link to="/master/admin"><i className="icon icon-user-o"/>
                  <IntlMessages id="sidebar.master.admin"/></Link>
              </Menu.Item>
              <Menu.Item key="user">
                <Link to="/master/user"><i className="icon icon-user"/>
                  <IntlMessages id="sidebar.master.user"/></Link>
              </Menu.Item>
              <Menu.Item key="category">
                <Link to="/master/category"><i className="icon icon-cards-list-view"/>
                  <IntlMessages id="sidebar.master.category"/></Link>
              </Menu.Item>
              <Menu.Item key="payment">
                <Link to="/master/payment"><i className="icon icon-litcoin"/>
                  <IntlMessages id="sidebar.master.payment"/></Link>
              </Menu.Item>
              <Menu.Item key="product">
                <Link to="/master/product"><i className="icon icon-card"/>
                  <IntlMessages id="sidebar.master.product"/></Link>
              </Menu.Item>
              <Menu.Item key="hamper">
                <Link to="/master/hamper"><i className="icon icon-card"/>
                  <IntlMessages id="sidebar.master.hamper"/></Link>
              </Menu.Item>
              <Menu.Item key="addon">
                <Link to="/master/addon"><i className="icon icon-card"/>
                  <IntlMessages id="sidebar.master.addon"/></Link>
              </Menu.Item>
              <Menu.Item key="banner">
                <Link to="/master/banner"><i className="icon icon-view"/>
                  <IntlMessages id="sidebar.master.banner"/></Link>
              </Menu.Item>
              {/* <Menu.Item key="table">
                <Link to="/master/table"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.master.table"/></Link>
              </Menu.Item>
              <Menu.Item key="form">
                <Link to="/master/form"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.master.form"/></Link>
              </Menu.Item> */}
            </SubMenu>
            <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="utility"
                    title={<IntlMessages id="sidebar.utility"/>}>
              {/* <Menu.Item key="homepict">
                <Link to="/utility/homepict"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.utility.homepict"/></Link>
              </Menu.Item> */}
              <Menu.Item key="maxorder">
                <Link to="/utility/maxorder"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.utility.maxorder"/></Link>
              </Menu.Item>
              <Menu.Item key="discount">
                <Link to="/utility/discount"><i className="icon icon-ticket-new"/>
                  <IntlMessages id="sidebar.utility.discount"/></Link>
              </Menu.Item>
              <Menu.Item key="discountaddon">
                <Link to="/utility/discountaddon"><i className="icon icon-ticket-new"/>
                  <IntlMessages id="sidebar.utility.discountaddon"/></Link>
              </Menu.Item>
              <Menu.Item key="coupon">
                <Link to="/utility/coupon"><i className="icon icon-tickets"/>
                  <IntlMessages id="sidebar.utility.coupon"/></Link>
              </Menu.Item>
              <Menu.Item key="testimony">
                <Link to="/utility/testimony"><i className="icon icon-testimonial"/>
                  <IntlMessages id="sidebar.utility.testimony"/></Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="trans"
                    title={<IntlMessages id="sidebar.trans"/>}>
              <Menu.Item key="order">
                <Link to="/trans/order"><i className="icon icon-orders"/>
                  <IntlMessages id="sidebar.trans.order"/></Link>
              </Menu.Item>
              <Menu.Item key="transfer">
                <Link to="/trans/transfer"><i className="icon icon-orders"/>
                  <IntlMessages id="sidebar.trans.transfer"/></Link>
              </Menu.Item>
            </SubMenu>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

