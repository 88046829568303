import enLang from "./entries/en-US";
// import zhLang from "./entries/zh-Hans-CN";
// import arLang from "./entries/ar_SA";
// import itLang from "./entries/it_IT";
// import esLang from "./entries/es_ES";
// import frLang from "./entries/fr_FR";
import idLang from "./entries/id-IN";
import {addLocaleData} from "react-intl";

const AppLocale = {
  id: idLang,
  en: enLang
};
addLocaleData(AppLocale.id.data);
addLocaleData(AppLocale.en.data);

export default AppLocale;
