import antdEn from "antd/lib/locale-provider/id_ID";
import appLocaleData from "react-intl/locale-data/id";
import idMessages from "../locales/id_IN.json";

const IdLang = {
  messages: {
    ...idMessages
  },
  antd: antdEn,
  locale: 'id-IN',
  data: appLocaleData
};
export default IdLang;
