const languageData = [
  {
    languageId: 'indonesia',
    locale: 'id',
    name: 'Indonesia',
    icon: 'id'
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }

];
export default languageData;
