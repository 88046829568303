import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import urlConfig from './../../util/urlGet';
import {Link} from 'react-router-dom';  

class UserInfo extends Component {

  render() {
    const {user} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/user/profile"><li>My Account</li></Link>
        
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={user && user.pict != '' ? urlConfig.urlFile + 'master/profpict/' + user.pict : require('assets/images/placeholder.jpg')}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

const mapStateToProps = ({auth}) => {
  const user = auth.authUser;
  return {user}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);
