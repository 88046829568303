const urlConfig = {
    // urlFile: 'http://localhost:8090/',
    urlFile: 'https://api.memoirehamper.com/',
    urlBanner: 'master/bannerimg/',
    urlHamper: 'master/hamperimg/',
    urlRes: 'master/getprodres/',
    urlProd: 'master/prodmpict/',
    urlInvitation: 'trans/invitationimg/',
    urlTrans: 'trans/transimg/'
}

export default urlConfig;