import React from "react";
import {Button, Checkbox, Form, Input, Row, Col} from "antd";

const FormItem = Form.Item;

class NotCompatible extends React.Component {
  

  render() {
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src='https://via.placeholder.com/272x395' alt='Neature'/>
                </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              Your browser is not compatible, please open it in following browser
              <div>
              <Row>
                <Col>
                  <img src={require("assets/images/chrome.png")} style={{width: 50}}/>
                </Col>
                <Col>
                  <img src={require("assets/images/safari.png")} style={{width: 50}}/>
                </Col>
                <Col>
                  <img src={require("assets/images/firefox.png")} style={{width: 50}}/>
                </Col>
              </Row>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}



export default NotCompatible;
